// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const URL = 'https://portal.smareno.np.isccloud.io';
const STAGE = 'smareno';

export const environment = {
  production: true, //either true or false
  NG_APP_COGNITO_USERPOOL_ID: 'us-east-2_VlNCOwmuD',
  NG_APP_COGNITO_CLIENT_ID: '1p8o01dldg9dk8ltaehs572lnh',
  NG_APP_COGNITO_REGION: 'us-east-2',
  APP_URL: `${URL}`,
  API_URL: `${URL}/v3/api`,
  STAGE: `${STAGE}`,
  SQLAAS_URL: '#sqlaas_url#',
};
